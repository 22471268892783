/**
 * Unpacks scoped window translations to be backwards compatible with unscoped messages
 * @param scope
 * @return {*}
 */
export function prepareMessages(scope) {
  let messages = {...window.localeData};
  for (let i = 0; i < Object.keys(messages).length; i++) {
    const locale = Object.keys(messages)[i];
    messages[locale] = messages[locale][scope];
  }
  return messages;
}