<script>
export default {};
</script>

<template>
  <div class="bg-light-green rounded-border-8 p-16 gap-16">
    <div class="dark-blue font-14-18 d-flex justify-content-between">
      <div>
        <p class="fw-bold font-16-24">{{ $t('bot.upsell_landing.title') }}</p>
        <p class="font-14-16 mb-12">{{ $t('bot.upsell_landing.subtitle') }}</p>
        <a
          class="clickable background-white font-14-16 fw-bold d-inline-block rounded-border-8 p-12 dark-blue"
          @click="$emit('click')"
        >
          {{ $t('bot.startChat') }}
        </a>
      </div>

      <img
        :src="require('images/price_bot/supportLogo.png')"
        :alt="$t('bot.upsell.image_alt')"
        height="94"
        width="94"
        loading="lazy"
      />
    </div>
  </div>
</template>
