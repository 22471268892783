import { ApolloClient, InMemoryCache } from '@apollo/client/core';

const client = new ApolloClient({
  // uri: 'https://staging-new.laundryheap.com/graphql',
  uri: window.graphql_path || '/graphql',
  cache: new InMemoryCache(),
  headers: {
    'x-locale': window.locale || 'en',
  },
});

export { client };
