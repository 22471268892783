<script>
import Modal from './Modal.vue';
import PricingBot from '../pricing_bot/PricingBot.vue';
import TextInput from '../TextInput.vue';

export default {
  components: { TextInput, PricingBot, Modal },
  props: {
    id: { type: String, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      message: '',
      sessionId: null,
      showHelpPicker: true,
    };
  },
  computed: {
    shouldShowHelpPicker() {
      return !!this.message?.toLowerCase()?.includes('specialist team');
    },
  },
  methods: {
    onMessageSend() {
      this.$refs.pricingBot?.onMessageSend(this.message);
      this.message = '';

      setTimeout(() => {
        this.$refs.modal?.scrollToBottom();
      }, 250);
    },
    onNewMessage() {
      setTimeout(() => {
        this.$refs.modal?.scrollToBottom();
      }, 250);
    },

    onSessionIdUpdate(sessionId) {
      this.sessionId = sessionId;
    },
    onTextAreaKeyPress(key) {
      if (!this.message || this.message.trim() === '') {
        return;
      }

      if (key === 'enter') {
        this.$refs.messageInput?.blur();
        this.onMessageSend();
      }
    },
  },
};
</script>

<template>
  <Modal
    :onClose="() => $emit('close', id)"
    noPaddingFooter
    :id="id"
    ref="modal"
    class="bot-modal-wrapper"
    :disableBackdropClose="true"
    :hideScrollbar="true"
  >
    <template #head>
      <p class="text-center">
        {{ $t('bot.title') }}
      </p>
    </template>
    <PricingBot
      ref="pricingBot"
      v-model="message"
      :session-id="sessionId"
      :onClose="() => $emit('close', id)"
      @newMessage="onNewMessage"
      @sessionIdUpdate="onSessionIdUpdate"
    />

    <template #foot class="bot-modal-bottom modal-footer">
      <div class="pricing-bot-message-wrapper">
        <TextInput
          v-model="message"
          name="message"
          class="d-block pricing-bot-text-input"
          ref="messageInput"
          :label="''"
          :placeholder="$t('bot.askUsAnything')"
          @keyUp="onTextAreaKeyPress"
        />

        <img
          :src="
            !message || message.trim() === ''
              ? require('images/price_bot/chatButtonDisabled.svg')
              : require('images/price_bot/chatButton.svg')
          "
          class="pricing-bot-message-button button p-0"
          :class="{ disabled: !message }"
          width="32"
          height="32"
          @click="onMessageSend"
        />
      </div>
    </template>
  </Modal>
</template>
