export default Vue =>
  new Vue({
    methods: {
      priceFromCents(cents, locale, currencyIsoCode) {
        const currencyLocale = locale || 'en-US';

        // BH needs to be handled separately, as they use 1/1000ths instead of cents
        const amount = cents / (currencyIsoCode == 'BH' ? 10.0 : 100.0);


        const isRound = amount % 1 === 0;
        return new Intl.NumberFormat(currencyLocale, {
          currency: currencyIsoCode,
          style: 'currency',
          maximumFractionDigits: isRound ? 0 : 2,
          minimumFractionDigits: isRound ? 0 : 2,
        }).format(amount);
      },
    },
  });
