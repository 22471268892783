var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modalWrapper",staticClass:"modal--backdrop initial",class:{ 'modal--prompt': _vm.prompt },on:{"click":_vm.onBackdropClicked}},[_c('div',{ref:"modal",staticClass:"modal--window d-flex flex-column",class:{
      'modal-washy-intercom': _vm.washyIntercomOpened,
      'modal--wide': _vm.wide,
      'modal--xwide': _vm.xwide,
      'modal--narrow': _vm.narrow,
      'no-padding': _vm.noPadding,
      'modal--color': _vm.colored,
      'full-height': _vm.fullHeight,
      'modal--transparent': _vm.transparent,
    },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.showHeader)?_c('header',{staticClass:"modal--head d-flex flex-row align-items-center py-18 px-16 py-md-18 px-md-16",class:({ 'no-title': !_vm.$slots.head, scrolled: _vm.bodyScrolled }, _vm.backgroundColor),style:(_vm.headerStyle)},[_c('div',{staticClass:"font-18-24 fw-normal flex-grow-1 fw-bold",class:{ 'deep-black': !_vm.colored }},[_vm._t("head")],2),_vm._v(" "),(_vm.closable)?[(_vm.colored || _vm.lightCloseButton)?_c('img',{staticClass:"clickable",attrs:{"src":require('images/icons/modal_close.svg'),"alt":_vm.$t('image_alts.closeModal'),"height":"24","width":"24"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCloseButtonClicked.apply(null, arguments)}}}):_c('img',{staticClass:"clickable",attrs:{"src":require('images/icons/modal_close.svg'),"alt":_vm.$t('image_alts.closeModal'),"height":"24","width":"24"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCloseButtonClicked.apply(null, arguments)}}})]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{ref:"modalBody",staticClass:"modal--body flex-grow-1",class:Object.assign({}, {hideScrollbar: _vm.hideScrollbar}, _vm.backgroundColor),on:{"scroll":_vm.computeBodyScroll}},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.foot)?_c('footer',{staticClass:"modal--footer",class:{ 'p-16': !_vm.noPaddingFooter, shadow: _vm.footerShadow },style:(_vm.footerStyle)},[_vm._t("foot")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }