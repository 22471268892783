<script>
export default {
  props: {
    placeholder: { type: String },
    label: { type: String , required: true },
    value: { type: String, required: false, default: '' },
    name: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    noLabel: { type: Boolean, default: false },
    maxlength: { type: Number, default: 524288 },
    uppercase: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    autocomplete: { type: String, default: null },
    type: { type: String, default: 'text' },
    error: { type: Boolean, default: false },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    active() {
      return this.focused;
    },
    hasText() {
      return Boolean(this.value);
    },
    placeholderText() {
      return this.focused ? '' : this.placeholder;
    },
    autocompleteValue() {
      return this.autocomplete || 'off';
    },
  },
  methods: {
    onInputFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    onInputBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    /**
     * This function exists so you can easily call blur on the component
     */
    blur() {
      (this.$refs.input)?.blur();
      this.onInputBlur();
    },
    focus() {
      (this.$refs.input)?.focus();
    },
    clear() {
      this.$emit('input', '');
    },
  },
};
</script>

<template>
  <div
    :class="{
      active: active,
      readonly,
      readonly: disabled,
      'no-label': noLabel,
      'has-error': error,
    }"
    class="lh-input-new"
  >
    <div class="d-flex align-items-center">
      <label :for="name">{{ label }}</label>
      <input
        :id="name"
        ref="input"
        :autocomplete="autocompleteValue"
        :class="{ 'text-uppercase': uppercase, 'pe-12': clearable, 'pe-38': !clearable }"
        :disabled="disabled"
        :maxlength="maxlength"
        :name="name"
        :placeholder="placeholderText"
        :readonly="readonly"
        :type="type"
        :value="value"
        @blur="onInputBlur"
        @focus="onInputFocus"
        @input="e => $emit('input', e.target.value)"
        @keyup.stop.prevent.esc="$emit('keyUp', 'esc')"
        @keyup.stop.prevent.enter="$emit('keyUp', 'enter')"
        @keyup.stop.prevent.down="$emit('keyUp', 'down')"
        @keyup.stop.prevent.up="$emit('keyUp', 'up')"
      />
      <img
        v-if="clearable && hasText"
        :src="require('images/icons/clear.svg')"
        alt="clear"
        class="lh-input--clear clickable"
        :class="{ 'me-36': clearable }"
        height="16"
        width="16"
        @click="clear"
      />
    </div>
    <div v-if="$slots.append" class="append">
      <slot name="append"></slot>
    </div>
  </div>
</template>
