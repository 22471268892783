export default {
  methods: {
    isFeatureEnabled(featureFlag) {
      return new Promise(function (resolve, reject) {
        if (window.posthog && typeof window.posthog.onFeatureFlags === 'function') {
          const timeout = setTimeout(() => resolve(false), 3000);

          window.posthog.onFeatureFlags(function (flags) {
            clearTimeout(timeout);
            if (!flags.includes(featureFlag)) {
              resolve(false);
            }
            resolve(window.posthog.isFeatureEnabled(featureFlag) || false);
          });
        } else {
          resolve(false);
        }
      });
    },
  },
};
