<script>
export default {
  props: {
    id: { type: String, default: null },
    title: { type: String, default: '' },
    closable: { type: Boolean, default: true },
    onClose: { type: Function, default: null },
    wide: { type: Boolean, default: false },
    xwide: { type: Boolean, default: false },
    narrow: { type: Boolean, default: false },
    prompt: { type: Boolean, default: false },
    colored: { type: Boolean, default: false },
    lightCloseButton: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    noPaddingFooter: { type: Boolean, default: false },
    /* Tell the modal it's on top of some other modal */
    stacked: { type: Boolean, default: false },
    bgPrimary: { type: Boolean, default: false },
    bgSkyBlue: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
    hideScrollbar: { type: Boolean, default: false },
    headerColour: { type: String, default: null },
    footerColour: { type: String, default: null },
    fullHeight: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    disableBackdropClose: { type: Boolean, default: false },
  },
  data() {
    return {
      bodyScrolled: false,
      footerShadow: false,
      washyIntercomOpened: false,
    };
  },
  computed: {
    backgroundColor() {
      if (this.bgPrimary) {
        return 'bg-primary-blue';
      }
      if (this.bgSkyBlue) {
        return 'bg-sky-blue';
      }
      return '';
    },
    headerStyle() {
      let style = {};
      if (this.headerColour) {
        style['backgroundColor'] = this.headerColour;
      }
      return style;
    },
    footerStyle() {
      let style = {};
      if (this.backgroundColor) {
        style['backgroundColor'] = this.backgroundColor;
      }
      if (this.footerColour) {
        style['backgroundColor'] = this.footerColour;
      }
      return style;
    },
  },
  created() {
    document.body.classList.add('lock-scroll');
    if (window.washyIntercomOpened) {
      this.washyIntercomOpened = window.washyIntercomOpened;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.modalWrapper.classList.remove('initial');
    }, 50);
  },
  destroyed() {
    if (!this.stacked) {
      document.body.classList.remove('lock-scroll');
    }
  },
  methods: {
    onBackdropClicked() {
      if (!this.disableBackdropClose) {
        this.closeModal();
      }
    },
    onCloseButtonClicked() {
      this.closeModal();
    },
    closeModal() {
      if (!this.closable) {
        return;
      }

      if (!this.stacked) {
        document.body.classList.remove('lock-scroll');
      }
      this.onClose ? this.onClose(this.id) : this.$outlets.remove(this.id);
    },
    computeBodyScroll(event) {
      const el = event?.target;

      if (!el) {
        return;
      }

      this.bodyScrolled = el.scrollTop > 0;
      this.footerShadow = !(el.scrollHeight - el.scrollTop - el.clientHeight < 1);
    },
    scrollToBottom() {
      this.$refs.modalBody.scrollTop = this.$refs.modalBody.scrollHeight;
    },
  },
};
</script>

<template>
  <div
    ref="modalWrapper"
    class="modal--backdrop initial"
    :class="{ 'modal--prompt': prompt }"
    @click="onBackdropClicked"
  >
    <div
      ref="modal"
      class="modal--window d-flex flex-column"
      :class="{
        'modal-washy-intercom': washyIntercomOpened,
        'modal--wide': wide,
        'modal--xwide': xwide,
        'modal--narrow': narrow,
        'no-padding': noPadding,
        'modal--color': colored,
        'full-height': fullHeight,
        'modal--transparent': transparent,
      }"
      @click.stop.prevent
    >
      <header
        v-if="showHeader"
        class="modal--head d-flex flex-row align-items-center py-18 px-16 py-md-18 px-md-16"
        :class="({ 'no-title': !$slots.head, scrolled: bodyScrolled }, backgroundColor)"
        :style="headerStyle"
      >
        <div class="font-18-24 fw-normal flex-grow-1 fw-bold" :class="{ 'deep-black': !colored }">
          <slot name="head"></slot>
        </div>
        <template v-if="closable">
          <img
            v-if="colored || lightCloseButton"
            :src="require('images/icons/modal_close.svg')"
            :alt="$t('image_alts.closeModal')"
            height="24"
            width="24"
            class="clickable"
            @click.stop.prevent="onCloseButtonClicked"
          />
          <img
            v-else
            :src="require('images/icons/modal_close.svg')"
            :alt="$t('image_alts.closeModal')"
            height="24"
            width="24"
            class="clickable"
            @click.stop.prevent="onCloseButtonClicked"
          />
        </template>
      </header>
      <div
        ref="modalBody"
        class="modal--body flex-grow-1"
        :class="{ hideScrollbar: hideScrollbar, ...backgroundColor }"
        @scroll="computeBodyScroll"
      >
        <slot></slot>
      </div>
      <footer
        v-if="$slots.foot"
        class="modal--footer"
        :class="{ 'p-16': !noPaddingFooter, shadow: footerShadow }"
        :style="footerStyle"
      >
        <slot name="foot"></slot>
      </footer>
    </div>
  </div>
</template>
